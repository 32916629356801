<template>
  <v-app>
    <div id="notfound">
      <div class="notfound">
        <div class="notfound-404">
          <h1>404</h1>
        </div>
        <h2>PAGE NOT FOUND</h2>
        <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
        <v-btn large depressed @click="home">
          <v-icon large>mdi-home-variant-outline</v-icon>Back to homepage
        </v-btn>
      </div>
    </div>
  </v-app>
</template>
<script>
// @ is an alias to /src
export default {
  name: "Home",
  components: {

  },
  data() {
    return {};
  },

  methods: {
    home() {
      this.$router.push({ name: "Home" });
    }
  }
};
// #notfound {
//   position: relative;
//   height: 100vh;
// }
</script>
<style scoped>
#notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 520px;
  width: 100%;
  text-align: center;
  line-height: 1.4;
  font-family: "Montserrat", sans-serif;
}
h1 {
  font-size: 146px;
}
p {
  color: #787878;
  font-weight: 300;
}
</style>
